<template>
    <div ref="box">
        <h2 style="font-size: 18px;text-align: center;">来飞网黑卡会员服务协议</h2>
        <div class="protocol_div">
            <p class="protocol_title">特别提示</p>
            <p>在您注册成为来飞网会员之前，请认真阅读本《来飞网黑卡会员协议》（以下简称“会员协议”），确保您充分理解会员协议中各条款，包括免除责任的条款及限制会员权利的条款。请您审慎阅读并选择接受或不接受本会员协议。</p>
            <p>本会员协议约定来飞网与您之间关于“黑卡会员”服务的各项事项。“会员”是指注册、登录、使用本会员协议中来飞网提供的各种服务的个人。黑卡会员服务是有偿的增值服务，会员必须完全同意本会员协议，并完成付费，才能享受相关服务。</p>
            <p>本会员协议是基于“来飞网”平台而制定的。您选择接受本会员协议，开通会员服务成为会员用户，即表示您同意接受本会员协议及各种规范、规则的各项约束，并将遵守。如果您不接受本会员协议，则不能享受本会员协议所涉会员服务。</p>
            <p class="protocol_title">一、总则</p>
            <p>1、来飞网是围绕店铺进行内容营销的网络服务平台。</p>
            <p>2、来飞网用户是指完成全部注册流程，愿意接受来飞网相关服务协议并在来飞网相关使用规范的规定下使用来飞网服务的注册用户。</p>
            <p>3、黑卡会员是来飞网为用户提供的有偿增值服务，用户向来飞网支付相关费用后，方可享受专门为会员提供的各项服务。</p>
            <p>4、黑卡会员必须遵守来飞网相关服务协议及本会员协议。</p>
            <p class="protocol_title">二、服务费用</p>
            <p>1、会员资格以月或年为单位计算（每月服务是当月日期至到次月相同日期为止，每年服务是当年日期到次年相同日期为止，没有相同日期按月最后一天计算）</p>
            <p>2、来飞网可以根据实际情况随时调整会员服务费用标准，经调整的会员服务费用标准将在会员资格支付页上显示，自公布的调整日后需要向来飞网付费的用户按调整后的服务费用标准支付后方可享有或延续会员资格。已按原会员服务费用标准购买会员服务的用户，其会员资格以及所享有的会员服务在已取得的会员资格期限内不受影响，无需补充缴纳差额。</p>
            <p class="protocol_title">三、服务内容</p>
            <p>来飞网将为会员在会员有效期内提供多种专享的权益，具体如下：</p>
            <p>1、<b>专属顾问</b>：来飞网为黑卡会员分配1对1的在线咨询师，全程为会员定制化解决有关店铺运营的方案。（比如，店铺如何在大众点评提升星级，如何获取更高的商户评分；店铺什么时间段上推广通更有性价比，团购套餐怎么配置等等）</p>
            <p>2、<b>点评补发</b>：来飞网为黑卡会员免费赠送30次/月的点评补发服务，通过信息技术为会员店铺召回曾经到店过的客户，并激励其补发点评，提升店铺人气。</p>
            <p>3、<b>店铺装修</b>：来飞网为黑卡会员提供1次/月的店铺装修服务，包括门店头图设计、缩略图设计等等。</p>
            <p>4、<b>购课优惠</b>：黑卡会员享受来飞网所有课程85折的优惠。</p>
            <p class="protocol_title">四、服务的开通和终止</p>
            <p>1、来飞网用户完成用户注册程序，并通过来飞网平台提供的付费途径完成会员费用的支付后，即取得来飞网会员资格。</p>
            <p>2、来飞网用户通过来飞网平台完成会员注册，所提交的内容与个人资料必须真实有效。当会员的个人资料发生变化时，会员应及时修改注册的个人资料，否则由此造成的会员权利不能全面有效行使或其他任何后果、责任由会员自行承担。会员同意：来飞网在提供会员服务、遵守来飞网相关服务协议的情况下使用会员的信息。</p>
            <p>3、会员资格有效期自来飞网会员服务开通之时起算。</p>
            <p>4、除非用户开通自动延续功能，会员资格有效期届满后，来飞网将终止向该用户提供会员增值服务，该用户可以通过续交会员服务费用延续会员资格。会员在会员资格有效期内续费，会员资格有效期将在原有效期基础上相应延长。此外，来飞网提供自动延续会员资格的功能，用户亦可在其会员资格有效期内开通该项功能，授权来飞网在用户会员资格有效期届满前，开始自动对其会员资格期限予以续期并从其用户账户中扣除相应的费用，并在续期届满后依此自动延续。如用户账户内费用不足以支付该续期费用，则该功能无法实现。</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Protocol",
        data(){
            return{
            }
        },
        mounted() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }
</script>

<style scoped>
    .protocol_div{
        line-height: 20px;
        padding:10px 20px 20px;
        font-size: 14px;
    }
    .protocol_div>p{
        margin:5px 0;
    }
    .protocol_title{
        font-weight: bold;
        margin: 1em 0 5px !important;
    }
</style>